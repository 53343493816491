import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/es"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import TextSection from "../components/ui-sections/text-section"
import ImageGallery from "../components/ui-sections/image-gallery"

const EspaisPage = (props) => {
	const heroText = "Espacios"
	const textSectionText = "Nuestras salas son amplias y ambiantades en nuestra tierra, el Alt Penedès. Disponemos de salones privados para eventos especiales de grupo, tanto celebraciones familiares o con amigos, como eventos de empresa. Nuestra bodega dispone de una mesa preciosa con capacidad para 12 personas para disfrutar, entre más de 300 referencias de vino, de un menú degustación que Pere les preparará a medida. También tenemos una sala de digestivos para después de comer. No queremos que tengas ninguna prisa. Queremos que estés como en casa."

	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Espacios"
	    	lang="es"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="espais" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allEspaisImagesEsYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[2, 5]} // This will be changed to `[2, 3]` later
	          images={data.allEspaisImagesEsYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default EspaisPage
